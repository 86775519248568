<template>
    <div v-if="fileUrl" style="border:1px solid #ccc;height:100% " v-wechat-title="$route.meta.title">
 
        <!-- <div :style="{'overflow':'auto','height':height,'width':'100%','margin': '1px auto' }">
            <pdf ref="pdf" 
                :page="pdfNum" 
                @progress="loadedRatio = $event" @num-pages="pageTotalNum=$event"  
                style="display: inline-block; width: 90%;height:100%;"
                :src="fileUrl">
            </pdf>
        </div> -->
         <!-- 使用iframe方法 -->
        <iframe :src="fileUrl" frameborder="0" style="width: 100%; height: 100%"></iframe>
    </div> 
</template>
<script>
import pdf from 'vue-pdf'
export default {
    name:'fullPreview',
    data () {
        return {
            fileUrl:'',
            pdfNum: 1,
            pageTotalNum: 1,  
            loadedRatio: 0, // 当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
        }
    },
    created () {
        this.fileUrl =this.$route.query.fileUrl
    },
    mounted () {
        //this.$refs.pdf.$el.style.width = parseInt(50) + "%"; 
    },
    computed: {
        height: function() {
            return (window.innerHeight - 50) + 'px';
        },   
    },
    methods: {
      // 上一页
        prePage() {
            let page = this.pdfNum
            page = page > 1 ? page - 1 : this.pageTotalNum
            this.pdfNum = page
        },
        // 下一页
        nextPage() {
            let page = this.pdfNum
            page = page < this.pageTotalNum ? page + 1 : 1
            this.pdfNum = page
        },  
    },
    components: {
        //pdf,
    }
}
</script>